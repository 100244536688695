.editor{
    display: block !important;
    position:static !important;
    /* width: 100% !important;
    height: 40vh !important; */
    margin-bottom: 1vmax;
}
.jodit-workplace{
    width: 100% !important;
    height: 36vh !important;   
}