.MuiDataGrid-columnHeaders{
    background-color: rgb(231, 231, 231);
    padding-left: 3vmax;
}
.MuiDataGrid-row{
    padding-left: 3vmax;
}
.MuiDataGrid-footerContainer{
    display: none;
    
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar{
    width: 7px;
    height: 7px;
    /* background-color: aqua; */
} 
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb{
    background-color: rgb(231, 231, 231);
}

.tableTop{
    padding: 1vmax;
    display: flex;
    justify-content: space-between;
}

.tableTop >form{
    display: flex;
    gap: 0.5rem;
    align-items: center;
justify-content: flex-end !important;
}

.Yes{
    background-color: rgb(151, 255, 151);
    color: rgb(0, 59, 0);
    padding: 0 .8rem;
    border-radius: 2rem;
}

.No{
    background-color: rgb(255, 173, 91);
    color: rgb(90, 57, 0);
    padding: 0 .8rem;
    border-radius: 2rem;
}

.paginationBox{
    padding: 1vmax;
    /* border-top: 1px solid rgb(212, 212, 212); */
    display: flex;
    justify-content: flex-end;
}
.actionBoxe{
    display: flex !important;
    flex-direction: row;
    gap: 0.5rem;
}
.actionBoxe > a > svg{
    color: rgb(30, 119, 203);
}
.totalComponents{
    width: 25%;
    /* height: 20; */
    display: flex;
    gap: 1rem;
    /* background-color: aquamarine; */
}