.topStyle{
    display: block;
    width: 100%;
    height: 300px;
    background-color: aqua;
    border-radius: 1rem 1rem 0 0;

}
.detailsBox{
    /* width: 100%; */
    margin:  0 4vmax;
    background-color: white;
    padding: 2vmax;
    margin-top: -230px;
    border-radius: 1rem;
}