.workshopbox{
    overflow: auto;
    height: 80vh;
}
.BackBox{
    display: block;
    position: absolute;
    /* background-color: aqua; */
    top: 0;
    left: 0;
}
.backBtn{
    width: 2.5vmax;
    height: 2.5vmax;
    padding: 0.1vmax;
    border: 3px solid gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.backBtn > svg{
    color: gray;
    font-size: 2vmax;
}

.imgBanner{
    padding:1vmax 6vmax;
}
.imgBanner > img{
    height: 70vh;
    width: 100%;
    border-radius: 0.8rem;
}

.bgColor{
    background-color: rgb(227, 254, 244);
}