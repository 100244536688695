.viewperson{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    border: 2px solid var(--bg1);
    padding: 1vmax;
    border-radius: 0.4rem;
    justify-content: space-between;
    margin-top: 0.1vmax;
    
}
.ol{
    list-style: none;
    list-style-type: none;
}
.viewperson > div:first-child{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
}
.viewperson1 > div:first-child{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
}
.viewperson1Box{
    margin-top: 0.1vmax;
    background-color: rgb(215, 250, 239);
}
.viewperson1{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: rgb(215, 250, 239);
    /* border: 2px solid var(--bg1); */
    padding: 1vmax;
    /* border-radius: 0.4rem; */
    justify-content: space-between;
    
}
.cancelDetails{
    width: 100%;
    padding: 0.5vmax;
    display: flex;
    gap: 2rem;
    background-color: rgb(255, 255, 255);

}
.bookingBox{
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.selectStyle{
    padding: 1vmax;
    font: 300 1.1vmax 'Raleway';
    border-radius: 0.4rem;
    color: gray;
}
.selectStyle:focus{
    border: 2px solid var(--bg1);
    color:  rgb(29, 29, 29);
    outline: none;

}