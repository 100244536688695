.pagination{
    display: flex;
    gap: 2;
    text-decoration: none;
    border: none !important;
}
.pagination > li{
    list-style: none;
    border-radius: 50%;

    /* padding: 0.5vmax 1vmax; */
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid gray; */
    font-size: large;
}
.pagination > li:hover{
    background-color: var(--bg1);
    > a{
        color: white !important;
    }

}
.pagination > li > a{
    text-decoration: none;
    color: gray !important;
}
.pagination .active{
    background-color: var(--bg1);
    > a{
        color: rgb(239, 239, 239) !important;
    }
}
.pagination .disabled{
    background-color: none !important;
    > a{
        color: rgb(247, 247, 247) !important;
    }
}
.pagination .disabled:hover{
    background-color: none !important;
}
