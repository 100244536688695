.teamContainers{
    padding-bottom: 4vmax;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1vmax;
}
.member-Box{
    width: 260px;
    /* max-height: 400px; */
    /* border: 1px solid rgb(220, 220, 220); */
    padding: 1vmax 1vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.5vmax; */
    border-radius: 1rem;
    background-image: linear-gradient(20deg , rgb(231, 255, 255), rgb(243, 255, 255), rgb(241, 241, 241));
    /* box-shadow: 0 0 10px rgb(184, 251, 203); */

}
.news-Box{
    width: 48%;
    /* max-height: 400px; */
    border: 1px solid rgb(220, 220, 220);
    padding: 1vmax 1vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.5vmax; */
    border-radius: 1rem;
    /* box-shadow: 0 0 10px rgb(184, 251, 203); */

}
.member-Box:hover{
    cursor: pointer;
    transition: all 0.5s;
    /* box-shadow: 0 0 5px var(--bg2); */
    background-color: rgb(243, 254, 254);

}
.news-Box > .namePositionBoxx{
    /* width: 100%; */
    padding: 1vmax 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;   
}
.namePositionBoxx{
    /* height: 150px; */
    padding: 1vmax 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.imgBoxl{
    /* height: 100px; */

    /* width: 100px;
    height: 100px;
    border: 1px solid gray;
    padding: 0.1vmax;
    border-radius: 50%; */
}
.imgBoxl > img{
    width: 70px;
    height: 70px ;
    border: 2px solid var(--bg1);
    padding: 0.3vmax;
    border-radius: 50%;
}
.news-Box , div > h5,
.member-Box > div > h5{
    color: blue;
    font: 300 1vmax 'Raleway';
    /* border: 1px solid black; */
    margin: 0vmax 0;
}
.dateBox > p,
.news-Box , div > p,
.member-Box > div > p{
    margin: 0vmax 0;
    text-align: center;
    color: var(--textC1);
    font: 300 0.9vmax 'Raleway';
}
.actionBox{
    width: 100%;
    border-top: 1px solid rgb(232, 232, 232);
    display: flex;
    flex-direction: column;
    padding: 0.8vmax;

    /* justify-content: center; */
}
.dateBox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.unlive{
    width: 6px ;
    height: 6px ;
    background-color: red;
    border-radius: 50%;
    box-shadow: 0 0 8px red;
}
.live{
    width: 6px ;
    height: 6px ;
    background-color: green;
    border-radius: 50%;
    box-shadow: 0 0 8px green;
}
.icon-Box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0 0 ;

}
.icon-Box > a > button,
.icon-Box > button{
    width: 30px;
    height: 30px;
    border: 2px solid var(--bg1);
    border-radius: 50%;
    padding: 0vmax;
    display: flex;
    align-items: center;
    transition: all 0.5s;
cursor: pointer;
    justify-content: center;
}
.icon-Box > a > button > svg,
.icon-Box > button >svg{
    color: var(--bg1);
    transition: all 0.5s;
    font-size: small;

}
.icon-Box > button:hover{
    border-color: var(--bg2);
    > svg{
        color: var(--bg2);
    }
}

.inpProfileBtn{
    background-color: rgb(236, 236, 255);
    border-radius: 0.4rem;
    border: 1px solid rgb(213, 213, 213);
}
.inpProfileBtn::-webkit-file-upload-button{
    padding: 0.7vmax;
    outline: none;
    border: none;
    background-color: rgb(27, 115, 193);
    color: white;

}

.actionBtnBox{
    display: flex;
    gap: 1rem;
}
