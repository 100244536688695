
.printBox{
    width: 100%;
    padding: 1vmax 1vmax;
    text-align: right;
}
.printBox > button{
    padding: 0.4vmax 1.6vmax;
    border: none;
    background-color: rgb(79, 208, 165);
    color: white;
    border-radius: 0.4rem;
    font-size: medium;
}
.printBox > button:hover{
    transition: all 0.5s;
    background-color: rgb(1, 114, 77);
    cursor: pointer;
}