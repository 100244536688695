:root{
    --bg1: #6098f2;
    --bg2: #61e5ff;

    --textC1:rgb(36, 36, 36);
    --textC2: white;
}

.aTag{
    text-decoration: none;
    color: var(--textC1);

}