.eventListBox {
    /* border: 1px solid rgb(102, 102, 102); */
    padding: 1vmax;
    border-radius: 0.8rem;
    box-shadow: 1px 1px 5px gray;
    background-color: rgb(241, 255, 250);
    margin-bottom: 0.5vmax;
}

.eventListBox>h3 {
    text-align: center;
    padding: 2vmax;
    background-color: rgb(0, 156, 170);
    border-radius: 0.8rem;
    color: var(--textC2);
}

.imgBox {
    border-radius: 0.8rem;
    overflow: hidden;
}

.bookingItem {
    border: 1px solid rgb(227, 227, 227);
    padding: 1vmax;
    border-radius: 0.8rem;
    margin: 0.6vmax 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bookingItem > div > h3{
    padding: 0;
    margin: 0;
    
}
.bookingCantainer {
    padding: 0 2vmax;
}

.magazineItem {
    border-bottom: 1px solid rgb(218, 218, 218);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vmax;
}
.magazineItem > div{
    width: 100%;
}
.magazineItem:first-child{
    border-top: 1px solid rgb(214, 214, 214);

}
.magazineItem > a{
    text-decoration: none;
}
.magazineItem:hover{
    cursor: pointer;
    background-color: rgb(215, 250, 240);
}
.magazinePage{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* padding: 1vmax; */
}
.magazinePage div:first-child{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 1vmax;

}
.magazinePage div:first-child{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 1vmax;

}
.magazinePage div > iframe{
    width: 100%;
    height: 85vh;
}
.eventName{
    width: 100%;
    background-color: rgb(216, 255, 253);
    padding: 0.4vmax 1vmax;
    text-align: left;
    border-radius: 0.4rem;
}