.orgBox{
    width: 100%;
    display: flex;
    flex-direction: column;
  
    background-image: linear-gradient(30deg,rgb(234, 255, 255),rgb(248, 248, 255),rgb(241, 241, 241));
}
.imgBoxLogoSide{
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 2vmax; */
    gap: 2rem;
}
.imgBoxLogoSide > img{
    width: 150px;
    height: 150px;

}
.imgBoxLogoSide > img:hover{
    transition: all 0.5s;
    transform: scale(1.1);
}
.orgContentBox{
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5;
}
.orgContentBox > div > h5 {
    font-size: 1.6vmax;
    font-weight: 600;
    font-family: Public Sans,sans-serif;
    color: var(--bg1);

}

.orgContentBox > div{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.3vmax;

}
.orgContentBox > div:hover{
    /* background-color: rgb(227, 255, 255); */

}
.orgContentBox > div > p:first-child{
    width: 40%;
    font-weight: 600;
    /* border: 1px solid gray; */
}

.orgContentBox > div > p:last-child{
    width: 100%;
    /* border: 1px solid gray; */

}
.orgContentBox > div >  p {
    font-size: 1.05vmax;
    font-family: Public Sans,sans-serif;
    /* padding-top: 0.5vmax; */
    text-align: left;
}
/* .orgContentBox > div >  p */
.editBtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0 0 ;

}
.editBtn > button{
    width: 42px;
    height: 42px;
    border: 3px solid var(--bg1);
    border-radius: 50%;
    padding: 0vmax;
    display: flex;
    align-items: center;
    transition: all 0.5s;
cursor: pointer;
    justify-content: center;
}
.editBtn > button >svg{
    color: var(--bg1);
    transition: all 0.5s;
    font-size: large;

}
.editBtn > button:hover{
    border-color: var(--bg2);
    > svg{
        color: var(--bg2);
    }
}
.certificateBox{
    width: 500px;
    height: 400px;
    /* border: 1px solid rgb(213, 213, 213); */
    padding: 2vmax;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}