.eventsContainers{
    padding-bottom: 4vmax;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1vmax;
}
.event-Box{
    width: 260px;
    /* max-height: 400px; */
    /* border: 1px solid rgb(220, 220, 220); */
    /* padding: vmax 1vmax; */
    /* padding: 0.2vmax; */
    /* padding-top: 1vmax; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.5vmax; */
    border-radius: 1rem;
    /* box-shadow: 0 0 10px rgb(184, 251, 203); */

}
.member-event-Box:hover{
    cursor: pointer;
    transition: all 0.5s;
    /* box-shadow: 0 0 5px var(--bg2); */
    background-color: rgb(243, 254, 254);

}

.namePositionBoxx{
    /* height: 150px; */
    padding: 1vmax 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.namePositionBoxx > h5{
    text-align: center;
    color: var(--bg1) !important;
}
.eventImgBoxl{
    /* height: 100px; */

    width: 100%;
    
}
.eventImgBoxl > img{
    width: 100%;
    height: 190px ;
    /* border: 2px solid var(--bg1); */
    
}
.event-Box > div > h5{
    color: blue;
    /* font: 300 1vmax ''; */
    font-size: 0.9vmax;
    /* border: 1px solid black; */
    margin: 0vmax 0;
}
.dateBox > p,
.event-Box > div > p{
    margin: 0vmax 0;
    text-align: center;
    color: var(--textC1);
    /* font: 300 0.9vmax 'Raleway'; */
    font-family: sans-serif;
    font-size: 0.9vmax;

}
.actionBox{
    width: 100%;
    /* border-top: 1px solid rgb(232, 232, 232); */
    display: flex;
    flex-direction: column;
    padding: 0vmax;
    /* padding-bottom: 0.4rem; */

    /* justify-content: center; */
}
.dateBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4vmax 0;
}
.unlive{
    width: 6px ;
    height: 6px ;
    background-color: red;
    border-radius: 50%;
    box-shadow: 0 0 8px red;
    margin: 0;

}
.center{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.live{
    width: 6px ;
    height: 6px ;
    background-color: green;
    border-radius: 50%;
    box-shadow: 0 0 8px green;
    margin: 0;
}
.event-icon-Box{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 0.5rem; */
    margin: 0 ;
    gap: 0;

}
.event-icon-Box > p{
    display: flex;
    align-items: center;
    gap: 1rem;
    /* font: 300 1vmax ''; */
    font-size: 0.9vmax;
    font-family: sans-serif;
    margin: 0.1rem;
}

.ei_btn{
    width: 30px;
    height: 30px;
    border: 2px solid var(--bg1);
    border-radius: 50%;
    padding: 0vmax;
    display: flex;
    align-items: center;
    transition: all 0.5s;
cursor: pointer;
    justify-content: center;
}
.ei_btn >svg{
    color: var(--bg1);
    transition: all 0.5s;
    font-size: 1.1vmax;

}
.ei_btn:hover{
    border-color: var(--bg2);
    > svg{
        color: var(--bg2);
    }
}

.inpProfileBtn{
    width: 100%;
    background-color: rgb(236, 236, 255);
    border-radius: 0.4rem;
    border: 3px solid rgb(123, 123, 123);
    padding: 0.8vmax;
}
.inpProfileBtn::-webkit-file-upload-button{
    padding: 0.7vmax;
    outline: none;
    border-radius: 0.4rem;

    border: none;
    background-color: rgb(27, 115, 193);
    color: white;

}

.actionBtnBox{
    display: flex;
    gap: 1rem;
}

.bannerPreview{
    width: 100%;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 0.4rem;
    /* initial-value: 'dd';
     */
     
}

.eventCreateBox{
    overflow: auto;
    height: 90vh;
}