.autoScroll{
    height: 90vh !important;
    overflow: auto !important;
}
.deleteBtn{
    outline: none;
    border: 1px solid red;
    color: red;
    padding: 0.2vmax 0.8vmax;
    /* font: auto; */
    border-radius: 2rem;
    cursor: pointer;
}
.deleteBtn:hover{
    background-color: red;
    color: white;
}